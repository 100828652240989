import React from "react";
import Layout from "../components/layout";
import ExtendedTitle from "../components/extended-title";
import {
	DevicePhoneMobileIcon,
	AtSymbolIcon,
	MapPinIcon,
} from "@heroicons/react/24/outline";
import Heading from "../components/heading";
import CaseCarousel from "../components/casecarousel";
import { useCaseOverview } from "../hooks/use-case-overview";
import { Seo } from "../components/seo";

const contactOptions = [
	{
		id: 1,
		title: "Telefoon",
		content: "+31 85 0644 995",
		icon: <DevicePhoneMobileIcon className="w-8 h-8 text-off-white" />,
	},
	{
		id: 2,
		title: "E-mail",
		content: "hello@wemakeitspark.nl",
		icon: <AtSymbolIcon className="w-8 h-8 text-off-white" />,
	},
	{
		id: 4,
		title: "Office",
		content: "Angelenweg 165J, Oss",
		icon: <MapPinIcon className="w-8 h-8 text-off-white" />,
	}
];

const ContactPage = () => {
	const cases = useCaseOverview();

	return (
		<Layout>
			<main className="">
				<ExtendedTitle subtitle="Let's get in touch!">
					Contact
				</ExtendedTitle>

				<section className="bg-gray border-gray border-t-2 border-b-2">
					<div className="grid lg:grid-cols-3 divide-x divide-gray divide-y-gray divide-y lg:divide-y-0">
						{contactOptions.map(function (item) {
							return (
								<div
									key={item.id}
									className="bg-off-white rounded-spark p-16 grow min-h-[300px] flex flex-col justify-center"
								>
									<div>
										<div className="rounded-md bg-gray p-3 inline-block mb-4">
											{item.icon}
										</div>
									</div>
									<Heading as="h3" size="text-lg">
										{item.title}
									</Heading>
									<p>{item.content}</p>
								</div>
							);
						})}
					</div>
				</section>
				<CaseCarousel cases={cases} darkBg={true} />
			</main>
		</Layout>
	);
};

export default ContactPage;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="Contact"
		title="Contact - SPARK - fotografie voor cultuur, sport en entertainment"
		description="Neem contact op met Spark voor fotografie en eindig je FOMO."
	/>
);
